import React from "react";
import "./batuk.css";
import { GrTechnology } from "react-icons/gr";
import { FaRegHandshake } from "react-icons/fa";
import { IoPersonOutline } from "react-icons/io5";
import { GoPeople } from "react-icons/go";
import { PiLeafLight } from "react-icons/pi";
import { MdOutlineMenuBook } from "react-icons/md";
// Import any other icons you need or update these imports as per your needs
import offerIcon from "../assets/landing-page/briefcase.png";

const batukData = [
  {
    icon: <GrTechnology style={{ height: "50px", width: "50px" }} />,
    title: "Exclusive Access",
    description:
      " Access our Partner Portal for seamless integration, featuring free software support, a web portal, SAL encryption, and Automated Workflow Distribution (AWD).",
  },
  {
    icon: <FaRegHandshake style={{ height: "50px", width: "50px" }} />,
    title: "Attractive Referrals",
    description:
      "Enjoy competitive referral fees with attractive earning potential.",
  },
  {
    icon: <IoPersonOutline style={{ height: "50px", width: "50px" }} />,
    title: "Enhanced Support",
    description:
      "Access dedicated account management and support for optimal performance.",
  },
  {
    icon: <GoPeople style={{ height: "50px", width: "50px" }} />,
    title: "Insightful Analytics",
    description:
      "Access detailed performance reports and insights to monitor and enhance your success.",
  },
  {
    icon: <PiLeafLight style={{ height: "50px", width: "50px" }} />,
    title: "Comprehensive Onboarding",
    description:
      "Receive full onboarding support to help you effectively promote Digital Gold.",
  },
  {
    icon: <MdOutlineMenuBook style={{ height: "50px", width: "50px" }} />,
    title: "Marketing Support",
    description:
      "We provide complete marketing support to promote Digital Gold.",
  },
];

const Batuk = () => {
  return (
    <div className="batuk-container">
      <div className="batuk-header">
        <img src={offerIcon} alt="Offerings Icon" className="batuk-icon" />
        <h2 className="batuk-title">Why Partner with Batuk?</h2>
      </div>
      <div className="batuk-cards-wrapper">
        <div className="batuk-cards-container">
          {batukData.map((data, index) => (
            <div className="batuk-landing-card" key={index}>
              <div className="icon-wrapper">{data.icon}</div>
              <h3 className="card-title">{data.title}</h3>
              <p className="card-description">{data.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Batuk;

// import React from "react";
// import "./batuk.css";
// import { GrTechnology } from "react-icons/gr";
// import { FaRegHandshake } from "react-icons/fa";
// import { IoPersonOutline } from "react-icons/io5";
// import { GoPeople } from "react-icons/go";
// import { PiLeafLight } from "react-icons/pi";
// import { MdOutlineMenuBook } from "react-icons/md";

// const batukData = [
//   {
//     icon: <GrTechnology />,
//     title: "Exclusive Access",
//     description:
//       " Access our Partner Portal for seamless integration and management.",
//   },
//   {
//     icon: goldIcon,
//     title: "Attractive Commissions",
//     description:
//       "  Enjoy competitive referral fees with attractive earning potential.",
//   },
//   {
//     icon: silverIcon,
//     title: "Enhanced Support",
//     description:
//       " Access dedicated account management and support for optimal performance.",
//   },
//   {
//     icon: fdIcon,
//     title: "Insightful Analytics",
//     description:
//       "Access detailed performance reports and insights to monitor and enhance your success.",
//   },
//   {
//     icon: spotGold,
//     title: "Comprehensive Onboarding",
//     description:
//       "  Receive full onboarding support to help you effectively promote Digital Gold.",
//   },
//   {
//     icon: spotGold,
//     title: "Marketing Support",
//     description:
//       "We provide complete marketing support to promote Digital Gold.",
//   },
// ];

// const Batuk = () => {
//   return (
//     <div className="batuk-container">
//       <div className="batuk-header">
//         <img src={offerIcon} alt="Offerings Icon" className="batuk-icon" />
//         <h2 className="batuk-title">Why Partner with Batuk?</h2>
//       </div>
//       <div className="batuk-cards-wrapper">
//         <div className="batuk-cards-container">
//           {batukData.map((data, index) => (
//             <div className="batuk-landing-card" key={index}>
//               <div className="icon-wrapper">{data.image}</div>
//               <h3 className="card-title">{data.title}</h3>
//               <p className="card-description">{data.description}</p>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Batuk;
