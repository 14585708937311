import React from "react";
import "./footer.css";
import Logo from "../assets/landing-page/partner-Landing-logo.png";
import playStore from "../assets/landing-page/playStore.png";
import appStore from "../assets/landing-page/appStore.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faFacebookF,
//   faTwitter,
//   faInstagram,
//   faLinkedinIn,
// } from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faTwitterSquare,
  faInstagram,
  faYoutubeSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-section logo-contact">
          <img
            src={Logo}
            alt="Batuk Logo"
            className="footer-logo"
            style={{ width: "13rem" }}
          />
          <div className="footer-address">
            <span className="footer-sapn">Address:</span>
            <p className="footer-p" style={{ marginBottom: "0px" }}>
              Office No 2, 3rd floor, A-1, Sector 9, Noida, Uttar Pradesh-
              201301
            </p>
          </div>
          <div className="footer-add">
            <span className="footer-sapn">Phone: </span>
            <a
              href="tel:+91844695359"
              className="footer-p"
              style={{ marginBottom: "0px", color: "white" }}
            >
              +91 844695359
            </a>
          </div>
          <div className="footer-add">
            <span className="footer-sapn">Email: </span>
            <a
              href="mailto:connect@batuk.in"
              className="footer-p"
              style={{ marginBottom: "0px", color: "white" }}
            >
              connect@batuk.in
            </a>
          </div>
        </div>
        <div className="footer-section links" style={{ paddingTop: "1.5rem" }}>
          <h4>Address</h4>
          <div className="footer-address">
            {/* <span className="footer-sapn">Address:</span> */}
            <p className="footer-p" style={{ marginBottom: "0px" }}>
              10/B Yunus Building, <br /> S.S Gaikwad Marg Near Metro Cinema,{" "}
              <br /> Mumbai, 400002
            </p>
          </div>
          <div className="footer-add">
            <span className="footer-sapn">Phone: </span>
            <a
              href="tel:+91844695359"
              className="footer-p"
              style={{ marginBottom: "0px", color: "white" }}
            >
              +91 844695359
            </a>
          </div>
          <div className="footer-add">
            <span className="footer-sapn">Email: </span>
            <a
              href="mailto:connect@batuk.in"
              className="footer-p"
              style={{ marginBottom: "0px", color: "white" }}
            >
              connect@batuk.in
            </a>
          </div>

          {/* <ul>
            <li>
              <a href="/home">Home</a>
            </li>
            <li>
              <a href="/about">About Us</a>
            </li>

            <li>
              <a href="/terms-conditions">Terms & Conditions</a>
            </li>
            <li>
              <a href="/privacy-policy">Privacy Policy</a>
            </li>
          </ul> */}
        </div>
        <div className="footer-section links" style={{ paddingTop: "1.5rem" }}>
          <h4>Resourse</h4>
          <ul>
            <li>
              <a href="/faq">FAQ</a>
            </li>
            <li>
              <a href="/blog">Blogs</a>
            </li>
            <li>
              <a href="/terms-conditions">Terms & Conditions</a>
            </li>
            <li>
              <a href="/privacy-policy">Privacy Policy</a>
            </li>
            <li>
              <a href="/shipping">Shipping & Returns</a>
            </li>
          </ul>
        </div>
        {/* <div className="footer-section links" style={{ paddingTop: "1.5rem" }}>
          <div className="subscribe">
            <input type="email" placeholder="Enter email" />
          </div>
          <button className="sub-button">Subscribe Newslatter</button>
        </div> */}
        <div className="footer-section links" style={{ paddingTop: "1.5rem" }}>
          <h4>Follow us on</h4>
          <div className="social-icons">
            <a
              href="https://www.facebook.com/batukify"
              title="facebook"
              target="_blank"
            >
              <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
            </a>
            <a href="https://x.com/Batukify" title="twitter">
              <FontAwesomeIcon icon={faTwitterSquare} size="2x" />
            </a>
            <a
              href="https://www.instagram.com/batukify"
              title="instagram"
              target="_blank"
            >
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
            {/* <a
              href="https://www.youtube.com/channel/UCva-iZHewRk2J70xv1Uteig?sub_confirmation=1"
              title="youtube"
              target="_blank"
            >
              <FontAwesomeIcon icon={faYoutubeSquare} size="2x" />
            </a> */}
            <a
              href="https://www.linkedin.com/company/bharat-batuk-private-limited/mycompany/"
              title="linkedin"
              target="_blank"
            >
              <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </a>
          </div>

          <p style={{ marginTop: "1rem" }}>Install App </p>
          <div className="footer-contact">
            <a
              href="https://play.google.com/store/apps/details?id=com.batuk.application&pcampaignid=web_share"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={playStore} alt="Batuk Logo" className="footer-logo" />
            </a>
            <a
              href="https://apps.apple.com/in/app/batuk/id6478106976"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={appStore} alt="Batuk Logo" className="footer-logo" />
            </a>
          </div>
        </div>
      </div>
      <hr />
      <div className="footer-bottom">
        <p>
          © Copyright 2024 Bharat Batuk Private Limited All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
